import "../scss/pages/home.scss";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);

const MARQUEES = document.querySelectorAll('.section .marquee')

/*gsap.timeline().to('.section--intro > svg text', {
  transformOrigin: "50% 50%",
  rotate: 360,
  duration: 200
})*/

if (document.getElementById('home-intro')) {
  let smallLogo = document.getElementById('small-animated-logo');
  let window_h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  gsap.to(smallLogo, {
    alpha: 1, duration: 0.5,
    scrollTrigger: {
      trigger: document.body,
      start: 'top -' + window_h,
      toggleActions: "play none none reverse"
    }
  });
}


MARQUEES.forEach(element => {
  const parent = element.parentElement;
  const textPath = element.querySelector('textPath');
  const path = element.querySelector('path');

  gsap.timeline({
    scrollTrigger: {
      trigger: parent,
      start: "top bottom",
      end: "+500%",
      markers: false,
      scrub: 2
    }
  })
    .set(textPath, {
      attr: {
        startOffset: () => (-1 * (path.getTotalLength()))
      }
    })
    .to(textPath, {
      attr: {
        startOffset: () => path.getTotalLength() / 16
      }
    })
});

